.shows-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.shows-title {
    text-align: center;
    color: #f9f9f9;
    margin-bottom: 30px;
    font-size: 36px;
}

.shows-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    justify-content: center;
}

.show-item {
    background-color: #6a329f;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.show-item:hover {
    transform: translateY(-5px);
}

.show-image {
    width: 100%;
    height: 250px;
    object-fit: cover;
}

.show-title {
    padding: 10px;
    text-align: center;
    font-size: 16px;
    color: #f9f9f9;
}

@media (max-width: 768px) {
    .shows-list {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }

    .show-image {
        height: 200px;
    }

    .show-title {
        font-size: 14px;
    }
}
