.personContainer {
  min-height: 100vh;
  background-color: #1a202c;
  color: #f7fafc;
  font-family: Arial, sans-serif;
}

.mainContent {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.personHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

.personImageContainer {
  margin-bottom: 1rem;
  margin-right: 2rem;
}

.personInfo {
  text-align: center;
  flex-grow: 1;
}

.personName {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.personBio {
  font-size: 1rem;
  color: #a0aec0;
  line-height: 1.5;
  margin-bottom: 1rem;
}

.buttonSection {
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
}

.button {
  padding: 0.5rem 1rem;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.playFeedButton {
  composes: button;
  background-color: #ab56fc;
}

.playFeedButton:hover {
  background-color: #553c9a;
}

.shareButton {
  composes: button;
  background-color: #4a5568;
}

.shareButton:hover {
  background-color: #2d3748;
}

.buttonIcon {
  margin-right: 0.5rem;
  width: 1em;
  height: 1em;
}

.toastSuccess {
  background-color: #1a202c !important;
  color: white !important;
}

.showsSection {
  background-color: #2d3748;
  border-radius: 0.5rem;
  padding: 1.5rem;
  margin-bottom: 2rem;
}

.sectionContainer {
  background-color: #2d3748;
  border-radius: 0.5rem;
  padding: 1.5rem;
  margin-bottom: 2rem;
}

@media (min-width: 768px) {
  .personHeader {
    flex-direction: row;
    align-items: flex-start;
  }

  .personImageContainer {
    margin-right: 2rem;
    margin-bottom: 0;
  }

  .personInfo {
    text-align: left;
  }
}
