.feedContainer {
  flex: 1;
  min-height: 0; /* This is crucial for nested flex scrolling */
  overflow: hidden;
}

.excerptContainer {
  height: 100%;
  overflow-y: auto;
  scroll-snap-type: y mandatory;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  /* padding: 16px; */
  background-color: #1a202c;
}

.excerptContainer::-webkit-scrollbar {
  display: none; /* WebKit */
}

.excerptThumbnail {
  position: relative;
  scroll-snap-align: start;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 16px;
  width: 100%;
  max-width: 800px;
  margin: 8px auto;
  background-color: #2d3748;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.excerptThumbnail:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.thumbnailOverlay {
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0;
  background-color: transparent;
}

.mainRow {
  display: flex;
  gap: 12px;
  width: 100%;
  align-items: flex-start;
}

.thumbnailImageContainer {
  position: relative;
  width: 160px;
  min-width: 160px;
  height: 90px;
  cursor: pointer;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin: 0;
}

.thumbnailImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
  transition: transform 0.3s ease;
}

.thumbnailImageContainer:hover .thumbnailImage {
  transform: scale(1.05);
}

.playButtonOverlay {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.thumbnailImageContainer:hover .playButtonOverlay {
  opacity: 1;
}

.playIcon {
  font-size: 1.5rem;
  color: white;
  transition: transform 0.3s ease;
}

.titleContainer {
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 0;
}

.thumbnailTitle {
  color: #f7fafc;
  cursor: pointer;
  transition: color 0.2s ease;
  font-weight: 600;
  font-size: 1.1rem;
  margin: 0;
}

.thumbnailTitle:hover {
  color: #c084fc;
}

.showName {
  font-size: 0.9rem;
  color: #c084fc;
  margin: 0;
  cursor: pointer;
  transition: color 0.2s ease;
}

.showName:hover {
  color: #9645e0;
}

.guestNames {
  font-size: 0.9rem;
  color: #a0aec0;
  margin: 0;
  cursor: pointer;
  transition: color 0.2s ease;
}

.guestNames:hover {
  color: #c084fc;
}

.tagsContainer {
  margin-top: 12px;
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  padding-bottom: 4px; /* Add some padding to account for any box-shadow */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.tagsContainer::-webkit-scrollbar {
  display: none;
}

/* Ensure tags within the container stay on one line */
.tagsContainer > div {
  display: inline-flex;
  flex-wrap: nowrap;
  gap: 8px;
}

@media (max-width: 480px) {
  .mainRow {
    flex-direction: column;
    align-items: stretch;
  }

  .thumbnailImageContainer {
    width: 100%;
    min-width: 100%;
    aspect-ratio: 16 / 9;
    height: auto;
  }

  .titleContainer {
    width: 100%;
    align-items: flex-start;
    text-align: left;
  }

  .thumbnailTitle {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.expandedExcerptView {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #1a202c;
  z-index: 1005;
  overflow-y: auto;
  padding-top: 60px;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(171, 86, 252, 0.8);
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.3s ease, background-color 0.3s ease;
  z-index: 1006;
}

.closeButton:hover {
  opacity: 1;
  background-color: rgba(171, 86, 252, 1);
}

.closeButton:not(:hover) {
  opacity: 0.8;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.overlayContent {
  position: relative;
  padding: 20px;
  border-radius: 8px;
  max-height: 90%;
  overflow-y: auto;
}

.refreshButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
  scroll-snap-align: end;
}

.refreshButton {
  background-color: #ab56fc;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.refreshButton:hover {
  background-color: #9645e0;
}

.refreshButton:active {
  transform: scale(0.95);
}

.refreshButton svg {
  margin-right: 8px;
}

.errorContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  padding: 2rem;
  color: #e53e3e;
}

.errorContainer h2 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

.noExcerptsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  padding: 2rem;
}

.noExcerptsContainer h2 {
  font-size: 1.8rem;
  color: #ab56fc;
  margin-bottom: 1rem;
}

.noExcerptsContainer p {
  font-size: 1.2rem;
  color: #a0aec0;
  margin-bottom: 2rem;
}
