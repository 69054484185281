.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.modalContent {
  background-color: #2d3748;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  position: relative;
  padding: 20px;
  color: white;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  padding: 5px;
}

.modalTitle {
  margin-bottom: 20px;
  font-size: 1.5rem;
}

.searchContainer {
  margin-bottom: 20px;
}

.searchInput {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #4a5568;
  background-color: #1a202c;
  color: white;
}

.listContainer {
  max-height: 60vh;
  overflow-y: auto;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
}

.item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-bottom: 8px;
}

.item:hover {
  background-color: #4a5568;
}

.selected {
  background-color: #553c9a;
}

.selected:hover {
  background-color: #6b46c1;
}

.personImage {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 12px;
}

.personInfo {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 12px;
}

.personName {
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.personDescription {
  font-size: 0.875rem;
  color: #a0aec0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.error {
  color: #fc8181;
  padding: 10px;
  margin: 10px 0;
  border-radius: 4px;
  background-color: rgba(252, 129, 129, 0.1);
}

.modalFooter {
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid #4a5568;
  text-align: right;
  color: #a0aec0;
}

.resultCount {
  font-size: 0.875rem;
}

.personsList {
  display: flex;
  flex-direction: column;
}

.inlineLoadingContainer {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.itemContent {
  display: flex;
  align-items: center;
  width: 100%;
}

.checkmark {
  margin-left: auto;
  color: #68D391;
  font-size: 1.2rem;
} 