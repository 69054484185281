.container {
  /* min-height: 100vh; */
  background-color: #1a202c;
  color: #f7fafc;
  font-family: sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 2.25rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.description {
  font-size: 1.125rem;
  margin-bottom: 2rem;
  max-width: 42rem;
  margin-left: auto;
  margin-right: auto;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  gap: 20px;
  align-items: center;
}

.button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.googleButton {
  background-color: white;
  border: 1px solid #ddd;
  color: #333;
}

.googleButton:hover {
  background-color: #e2e8f0;
}

.loadingButton {
  background-color: #4a5568;
  color: white;
  cursor: not-allowed;
}

.googleLogo {
  width: 18px;
  height: 18px;
}

.errorMessage {
  color: #fc8181;
}

@media (min-width: 768px) {
  .main {
    padding: 4rem 1rem;
  }

  .title {
    font-size: 3rem;
  }

  .description {
    font-size: 1.25rem;
  }
}

.emailSignIn {
  width: 100%;
}

.emailInput {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #000000;
}

.emailButton {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #ab56fc;
  color: white;
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.emailButton:hover {
  background-color: #553c9a;
}

.successMessage {
  color: #4caf50;
  margin-top: 20px;
  text-align: center;
}

.divider {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #ddd;
  line-height: 0.1em;
  margin: 20px 0;
}

.divider span {
  background: #fff;
  padding: 0 10px;
  color: #666;
  font-size: 14px;
}

.emailButton:disabled {
  background-color: #4a5568;
  cursor: not-allowed;
  opacity: 0.7;
}
