.searchContainer {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  padding-top: 84px;
  padding-bottom: 60px;
}

.searchForm {
  display: flex;
  margin-bottom: 20px;
  gap: 8px;
  position: relative;
}

.searchInputWrapper {
  position: relative;
  flex-grow: 1;
}

.searchInput {
  width: 100%;
  padding: 12px 16px;
  font-size: 16px;
  border: 2px solid rgba(171, 86, 252, 0.2);
  border-radius: 8px;
  color: #2d3748;
  background-color: rgba(255, 255, 255, 0.9);
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.searchInput:focus {
  outline: none;
  border-color: #ab56fc;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(171, 86, 252, 0.15);
}

.searchInput::placeholder {
  color: #a0aec0;
}

.searchButton {
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 500;
  background-color: #ab56fc;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 8px;
  box-shadow: 0 2px 4px rgba(171, 86, 252, 0.2);
}

.searchButton:hover {
  background-color: #9645e3;
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(171, 86, 252, 0.3);
}

.searchButton:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(171, 86, 252, 0.2);
}

.searchButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.searchButton:disabled:hover {
  background-color: #ccc;
  transform: none;
  box-shadow: none;
}

.searchIcon {
  display: none;
  font-size: 18px;
}

.error {
  color: #dc3545;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
}

.noResults {
  text-align: center;
  font-size: 18px;
  color: #6c757d;
  margin-top: 40px;
}

.filterSection {
  margin: 20px 0;
  padding: 0.5rem;
  background-color: #2d3748;
  border-radius: 0.5rem;
}

.filterHeader {
  font-size: 1.2rem;
  font-weight: 500;
  color: #f7fafc;
  margin-bottom: 1rem;
}

.filterOptions {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  min-height: 44px;
  padding: 0.5rem;
  background: rgba(26, 26, 26, 0.6);
  border-radius: 0.5rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
  scrollbar-color: #4a5568 transparent;
}

.filterOptions::-webkit-scrollbar {
  height: 6px;
}

.filterOptions::-webkit-scrollbar-track {
  background: transparent;
}

.filterOptions::-webkit-scrollbar-thumb {
  background-color: #4a5568;
  border-radius: 3px;
}

.filterOptions::-webkit-scrollbar-thumb:hover {
  background-color: #553c9a;
}

.filterOption {
  display: inline-flex;
  flex: 0 0 auto;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: #4a5568;
  border: none;
  border-radius: 2rem;
  cursor: pointer;
  transition: background-color 0.2s;
  user-select: none;
}

.filterOption:hover {
  background-color: #2d3748;
  transform: none;
}

.filterOption.selected {
  background-color: #553c9a;
  border: none;
  box-shadow: none;
}

.filterOption.selected:hover {
  background-color: #4a3294;
}

.filterOptionLabel {
  font-size: 14px;
  font-weight: 500;
  color: #f7fafc;
  white-space: nowrap;
}

.filterOption.selected .filterOptionLabel {
  color: #f7fafc;
}

/* Responsive styles */
@media (max-width: 768px) {
  .filterSection {
    margin: 1rem 0;
    padding: 1rem;
  }

  .filterOption {
    padding: 0.375rem 0.75rem;
  }

  .filterOptionLabel {
    font-size: 0.875rem;
  }
}

@media (max-width: 480px) {
  .filterSection {
    padding: 0.75rem;
  }

  .filterOption {
    padding: 0.25rem 0.5rem;
  }

  .filterOptionLabel {
    font-size: 0.75rem;
  }
}

/* Responsive design */
@media (max-width: 600px) {
  .searchForm {
    flex-direction: row;
    gap: 8px;
  }

  .searchInput {
    flex: 1;
  }

  .searchButton {
    padding: 10px;
    width: auto;
    min-width: 44px;
  }

  .searchButtonText {
    display: none;
  }

  .searchIcon {
    display: block;
    margin: 0 auto;
  }

  .resultCount {
    font-size: 12px;
    right: 8px;
  }

  .searchContainer {
    padding: 12px;
    padding-top: 64px;
    padding-bottom: 60px;
  }
}

.resultCount {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #718096;
  font-size: 14px;
  pointer-events: none;
}

.searchContent {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
}
