.player-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
}

.controls-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.custom-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.control-button {
  background: none;
  border: none;
  color: #fff;
  font-size: 36px;
  cursor: pointer;
}

.control-button:hover {
  color: #ab56fc;
}

.play-pause-button {
  font-size: 72px;
}

.time-display {
  font-size: 12px;
  color: #fff;
  text-align: center;
  margin-top: 10px;
}

.styled-react-player {
  margin-bottom: 10px;
}

.styled-react-player audio {
  filter: invert(100%) hue-rotate(180deg);
  background-color: #333;
  border-radius: 5px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-icon {
  animation: spin 1s linear infinite;
}
