.footer {
  @apply text-gray-400 py-6 mt-auto;
}

.footerContent {
  @apply container mx-auto px-4 text-center text-sm md:text-base;
}

@media (max-width: 767px) {
  .footer {
    padding-bottom: calc(1.5rem + 64px); /* Adjust this value based on your mobile navbar height */
  }
}
