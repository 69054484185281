.listContainer {
  position: relative;
  overflow-x: hidden;
  margin-top: 20px;
  background-color: #2d3748;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-height: 600px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.listWrapper {
  overflow-y: auto;
  flex-grow: 1;
  position: relative;
  scrollbar-width: thin;
  scrollbar-color: #4a5568 #2d3748;
  padding-bottom: 40px;
}

/* Webkit scrollbar styling */
.listWrapper::-webkit-scrollbar {
  width: 8px;
}

.listWrapper::-webkit-scrollbar-track {
  background: #2d3748;
  border-radius: 4px;
}

.listWrapper::-webkit-scrollbar-thumb {
  background: #4a5568;
  border-radius: 4px;
}

.listWrapper::-webkit-scrollbar-thumb:hover {
  background: #718096;
}

.list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.listItem {
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-bottom: 1px solid #4a5568;
  gap: 12px;
  margin-bottom: 12px;
}

.listItem:last-child {
  margin-bottom: 0;
}

.mainRow {
  display: flex;
  gap: 12px;
  width: 100%;
}

.tagsRow {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  padding-bottom: 12px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.tagsRow::-webkit-scrollbar {
  display: none;
}

/* Ensure tags within the row stay on one line */
.tagsRow > div {
  display: inline-flex;
  flex-wrap: nowrap;
}

.thumbnailContainer {
  position: relative;
  width: 160px;
  min-width: 160px;
  height: 90px;
  cursor: pointer;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.thumbnailImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
  transition: transform 0.3s ease;
}

.thumbnailContainer:hover .thumbnailImage {
  transform: scale(1.05);
}

.playOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.thumbnailContainer:hover .playOverlay {
  opacity: 1;
}

.playIcon {
  color: white;
  font-size: 1.5rem;
}

.contentContainer {
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.showLink {
  color: #c084fc;
  cursor: pointer;
  transition: color 0.2s ease;
}

.showLink:hover {
  color: #9645e0;
}

.guestLink {
  color: #a0aec0;
  cursor: pointer;
  transition: color 0.2s ease;
}

.guestLink:hover {
  color: #c084fc;
}

.title {
  color: #f7fafc;
  cursor: pointer;
  transition: color 0.2s ease;
  font-weight: 600;
  font-size: 1.1rem;
}

.title:hover {
  color: #c084fc;
}

.metaInfo {
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: #a0aec0;
  font-size: 0.9rem;
}

.metaRow {
  display: flex;
  align-items: center;
  gap: 8px;
}

.metaDivider {
  color: #4a5568;
}

.tagsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap !important;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  max-width: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding-bottom: 4px;
}

.tagsContainer::-webkit-scrollbar {
  display: none;
}

.tagsContainer > div {
  display: flex;
  flex-wrap: nowrap !important;
  min-width: min-content;
  width: auto;
}

@media (max-width: 768px) {
  .listItem {
    padding: 8px;
    gap: 8px;
    margin-bottom: 12px;
  }

  .mainRow {
    gap: 12px;
  }
}

@media (max-width: 480px) {
  .mobileStack {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
  }

  .mainRow {
    flex-direction: column;
    align-items: stretch;
  }

  .thumbnailContainer {
    min-width: 100%;
    max-width: 100%;
    aspect-ratio: 16 / 9;
    height: auto;
  }

  .contentContainer {
    width: 100%;
    align-items: flex-start;
    text-align: left;
  }
}
