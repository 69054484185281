.not-found-page {
  min-height: 100vh;
  background-color: #111827; /* bg-gray-900 */
  color: #f3f4f6; /* text-gray-100 */
  font-family: sans-serif;
}

.not-found-container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 1rem;
}

.not-found-header {
  padding: 1.5rem 0;
}

.not-found-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.not-found-logo {
  font-size: 1.25rem;
  font-weight: bold;
}

.not-found-link {
  font-size: 0.875rem;
  color: #c084fc; /* text-purple-400 */
  transition: color 0.3s;
}

.not-found-link:hover {
  color: #d8b4fe; /* text-purple-300 */
}

.not-found-main {
  padding: 2rem 0;
  text-align: center;
}

.not-found-title {
  font-size: 2.25rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.not-found-message {
  font-size: 1.125rem;
  margin-bottom: 2rem;
  max-width: 42rem;
  margin-left: auto;
  margin-right: auto;
}

.not-found-button {
  display: inline-flex;
  align-items: center;
  background-color: #9333ea; /* bg-purple-600 */
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  font-size: 1.125rem;
  font-weight: 600;
  transition: background-color 0.3s;
}

.not-found-button:hover {
  background-color: #7e22ce; /* bg-purple-700 */
}

.not-found-button-icon {
  margin-right: 0.5rem;
}

.not-found-footer {
  padding: 1.5rem 0;
  text-align: center;
  color: #9ca3af; /* text-gray-400 */
  font-size: 0.875rem;
}

@media (min-width: 768px) {
  .not-found-logo {
    font-size: 1.5rem;
  }

  .not-found-link {
    font-size: 1rem;
  }

  .not-found-main {
    padding: 4rem 0;
  }

  .not-found-title {
    font-size: 3rem;
  }

  .not-found-message {
    font-size: 1.25rem;
  }

  .not-found-footer {
    font-size: 1rem;
  }
}

