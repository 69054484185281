.dialog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.dialogContent {
  background-color: #2d3748;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  position: relative;
  padding: 20px;
  color: white;
}

.dialogTitle {
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: white;
}

.textField {
  margin-top: 16px;
  background-color: #1a202c;
  border-radius: 4px;
}

.textField :global(.MuiOutlinedInput-root) {
  color: white;
}

.textField :global(.MuiOutlinedInput-notchedOutline) {
  border-color: #4a5568;
}

.textField :global(.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline) {
  border-color: #6b46c1;
}

.textField :global(.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline) {
  border-color: #553c9a;
}

.alert {
  margin-bottom: 16px;
}

.dialogActions {
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid #4a5568;
  text-align: right;
}

.button {
  text-transform: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.submitButton {
  composes: button;
  background-color: #553c9a;
  color: white;
  border: none;
}

.submitButton:hover {
  background-color: #6b46c1;
}

.submitButton:disabled {
  background-color: #4a5568;
  color: #a0aec0;
  cursor: not-allowed;
}

.cancelButton {
  composes: button;
  background: none;
  border: none;
  color: #a0aec0;
  margin-right: 10px;
}

.cancelButton:hover {
  background-color: rgba(74, 85, 104, 0.2);
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  padding: 5px;
}
  