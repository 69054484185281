.container {
    max-width: 1280px;
    margin: 0 auto;
    padding: 1rem;
}

.header {
    text-align: center;
    margin-bottom: 3rem;
}

.title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 1rem;
}

.description {
    font-size: 1.125rem;
    color: #cbd5e0;
    max-width: 600px;
    margin: 0 auto;
}

.section {
    margin-top: 2rem;
    background-color: #2d3748;
    border-radius: 0.5rem;
    padding: 2rem;
    color: #ffffff;
}

.titleContainer {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.sectionIcon {
    color: #9f7aea;
    font-size: 1.5rem;
    flex-shrink: 0;
}

.sectionTitle {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
    color: #f7fafc;
}

.sectionDescription {
    font-size: 1.0rem;
    line-height: 1.6;
    color: #cbd5e0;
    margin-bottom: 1.5rem;
    letter-spacing: 0.015em;
    font-weight: 300;
}

.profileContent {
    text-align: center;
    padding: 1rem;
}

.profileCard {
    background-color: #1f2937;
    padding: 1.5rem;
    border-radius: 0.5rem;
    max-width: 1280px;
    margin: 0 auto;
}

.profileImage {
    width: 6rem;
    height: 6rem;
    border-radius: 9999px;
    margin: 0 auto 1rem auto;
}

.userIcon {
    width: 6rem;
    height: 6rem;
    color: #a78bfa;
    margin: 0 auto 1rem auto;
}

.infoText {
    font-size: 1.125rem;
    margin-bottom: 0.5rem;
}

.infoLabel {
    font-weight: bold;
}

.signOutButton {
    background-color: #9333ea;
    color: white;
    font-weight: bold;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    transition: background-color 0.3s ease;
}

.signOutButton:hover {
    background-color: #7e22ce;
}

.preferencesTitle {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 1.5rem 0 1rem 0;
}

.errorMessage {
    color: #f87171;
}

.preferencesData {
    text-align: left;
    background-color: #374151;
    padding: 1rem;
    border-radius: 0.25rem;
    overflow: auto;
}

@media (max-width: 768px) {
    .container {
        padding: 1rem;
    }

    .title {
        font-size: 2rem;
    }

    .description {
        font-size: 1rem;
    }

    .sectionTitle {
        font-size: 1.25rem;
    }
}

@media (min-width: 768px) {
    .container {
        padding: 2rem;
    }
}

.historySection {
    /* margin-top: 2rem; */
    background-color: #1f2937;
    border-radius: 0.5rem;
    padding: 1rem;
    max-width: 1280px;
    margin: 1rem auto;
}

.tableContainer {
    width: 100%;
    overflow-x: auto;
    margin-top: 0.5rem;
}

.loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 200px;
}

.noResults {
    color: #a0aec0;
    background-color: #2d3748;
    border-radius: 0.5rem;
    text-align: left;
    padding: 1rem;
    margin: 0;
    display: block;
}

.subsectionTitle {
    font-size: 1.25rem;
    font-weight: 600;
    color: #f7fafc;
    margin: 1.5rem 0 1rem;
}

.divider {
    height: 1px;
    background-color: rgba(255, 255, 255, 0.1);
    margin: 2rem 0;
}

.initialsAvatar {
    width: 6rem;
    height: 6rem;
    border-radius: 9999px;
    background-color: #9333ea;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.5rem;
    font-weight: bold;
    margin: 0 auto 1rem auto;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
}

.overlayContent {
    position: relative;
    padding: 20px;
    border-radius: 8px;
    max-height: 90%;
    overflow-y: auto;
}

.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: rgba(171, 86, 252, 0.8);
    color: white;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: opacity 0.3s ease, background-color 0.3s ease;
    z-index: 1006;
}

.closeButton:hover {
    opacity: 1;
    background-color: rgba(171, 86, 252, 1);
}

.closeButton:not(:hover) {
    opacity: 0.8;
}
