.topicsContainer {
  margin-bottom: 20px;
}

.topicsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px 0;
}

.topicsTitle {
  margin: 0;
  font-size: 1.2em;
  color: #333;
}

.tagsWrapper {
  max-height: 300px;
  overflow-y: auto;
  padding-right: 10px;
}

.tagsWrapper::-webkit-scrollbar {
  width: 8px;
}

.tagsWrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.tagsWrapper::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.tagsWrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.tagsList {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 8px;
}

.tagBubble {
  border: none;
  border-radius: 16px;
  cursor: pointer;
  transition: all 0.3s;
  margin: 2px;
}

.tagBubble:hover {
  opacity: 0.8;
}

.showAllButton {
  font-size: 1em;
  font-weight: bold;
  padding: 8px 16px;
}
