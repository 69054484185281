.container {
    color: #f3f4f6;
    font-family: sans-serif;
  }
  
  .main {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem 1rem;
    text-align: center;
  }
  
  .title {
    font-size: 2.25rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    color: #ab56fc;
  }
  
  .cardContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.5rem;
  }
  
  .card {
    background-color: #1f2937;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 100%;
    max-width: 300px;
    text-align: left;
  }
  
  .cardImage {
    width: 100%;
    height: auto;
  }
  
  .cardTitle {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 1rem;
    color: #ab56fc;
  }
  
  .cardSubtitle {
    font-size: 1rem;
    margin: 0 1rem 1rem;
    line-height: 1.5;
  }
  
  .link {
    color: #c084fc;
    transition: color 0.3s;
  }
  
  .link:hover {
    color: #ddd6fe;
  }
  
  @media (min-width: 768px) {
    .main {
      padding: 4rem 1rem;
    }
  
    .title {
      font-size: 2.5rem;
      margin-bottom: 2rem;
    }
  }
  
  .welcome {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 2rem;
    color: #f3f4f6;
    text-align: center;
    background: linear-gradient(135deg, #4f46e5 25%, #db2777 45%, #4f46e5 70%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
  
  .featuresGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    padding: 2rem 0;
    justify-items: center;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .featureCard {
    padding: 2rem;
    border-radius: 16px;
    position: relative;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 400px;
  }
  
  .featureCard:hover {
    transform: translateY(-5px);
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.2);
  }
  
  .featureCard::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.1);
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .featureCard:hover::before {
    opacity: 1;
  }
  
  .featureTitle {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: white;
    position: relative;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .featureSubtitle {
    font-size: 1rem;
    line-height: 1.6;
    color: rgba(255, 255, 255, 0.9);
    position: relative;
  }
  
  @media (max-width: 768px) {
    .featuresGrid {
      grid-template-columns: 1fr;
      padding: 1rem;
    }
  
    .welcome {
      font-size: 2rem;
      margin-bottom: 1.5rem;
    }
  }
  
  .tabs {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 2rem;
  }
  
  .tab {
    padding: 0.75rem 2rem;
    font-size: 1.1rem;
    font-weight: 600;
    border: none;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.7);
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .tab:hover {
    background: rgba(255, 255, 255, 0.15);
    color: rgba(255, 255, 255, 0.9);
  }
  
  .activeTab {
    background: rgba(255, 255, 255, 0.2);
    color: white;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  }
  
  .subtitle {
    font-size: 1.25rem;
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 3rem;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
  
  @media (max-width: 768px) {
    .subtitle {
      font-size: 1.1rem;
      margin-bottom: 2rem;
      padding: 0 1rem;
    }
  }
  
  .cardLink {
    text-decoration: none;
    display: block;
    width: 100%;
    justify-self: center;
    max-width: 400px;
  }
  
  .cardLink:hover .featureCard {
    transform: translateY(-5px);
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.2);
  }