.showImageContainer {
  overflow: hidden;
  background-color: #4a5568;
  display: flex;
  align-items: center;
  justify-content: center;
}

.showImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.showImagePlaceholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: white;
  font-size: 16px;
}
