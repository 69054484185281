.scrollContainer {
  width: 100%;
  overflow: hidden;
  padding: 1rem 0;
}

.episodesList {
  display: flex;
  gap: 1rem;
  overflow-x: auto;
  padding: 0.5rem;
  scrollbar-width: thin;
  scrollbar-color: #9f7aea #2d3748;
}

.episodesList::-webkit-scrollbar {
  height: 8px;
}

.episodesList::-webkit-scrollbar-track {
  background: #2d3748;
  border-radius: 4px;
}

.episodesList::-webkit-scrollbar-thumb {
  background: #9f7aea;
  border-radius: 4px;
}

.episodeCard {
  flex: 0 0 auto;
  width: 200px;
  background: #1f2937;
  border-radius: 0.5rem;
  padding: 0.75rem;
  cursor: pointer;
  transition: transform 0.2s;
}

.episodeCard:hover {
  transform: translateY(-2px);
}

.episodeImageContainer {
  width: 100%;
  aspect-ratio: 1;
  border-radius: 0.375rem;
  overflow: hidden;
  margin-bottom: 0.75rem;
}

.episodeImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.placeholderImage {
  width: 100%;
  height: 100%;
  background: #374151;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  color: #9f7aea;
}

.episodeInfo {
  text-align: left;
}

.episodeTitle {
  font-size: 0.875rem;
  font-weight: 600;
  color: #f7fafc;
  margin: 0 0 0.25rem 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.episodeShow {
  font-size: 0.75rem;
  color: #9f7aea;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
} 