.toastError {
  /* Basic styling for error toasts */
  background-color: #ff4444 !important;
  color: white !important;
  border-radius: 4px;
  font-size: 14px;
  padding: 16px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

/* Optional: Style the toast progress bar */
.toastError :global(.Toastify__progress-bar) {
  background-color: rgba(255, 255, 255, 0.7);
}
