.landingPage {
  min-height: 100vh;
  background-color: #1a202c;
  color: #f3f4f6;
  font-family: sans-serif;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;
  text-align: center;
}

.title {
  font-size: 2.25rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.subtitle {
  font-size: 1.125rem;
  margin-bottom: 2rem;
  max-width: 42rem;
  margin-left: auto;
  margin-right: auto;
}

.featureContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 3rem;
}

.featureCard {
  background-color: #1f2937;
  padding: 1.5rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 1.5rem;
}

.featureIcon {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
}

.featureTitle {
  font-size: 1.125rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.featureDescription {
  font-size: 0.875rem;
}

@media (min-width: 768px) {
  .container {
    padding: 4rem 1rem;
  }

  .title {
    font-size: 3rem;
  }

  .subtitle {
    font-size: 1.25rem;
  }

  .featureContainer {
    flex-direction: row;
    gap: 1.5rem;
  }

  .featureCard {
    margin-bottom: 0;
  }

  .featureIcon {
    height: 5rem;
  }

  .featureTitle {
    font-size: 1.25rem;
  }

  .featureDescription {
    font-size: 1rem;
  }
}
