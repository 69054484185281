.mainContainer {
    max-width: 1280px;
    margin: 0 auto;
    padding: 1rem;
}

.section {
    margin-top: 2rem;
    background-color: #2d3748;
    border-radius: 0.5rem;
    padding: 2rem;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 250px);
}

.titleContainer {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.queueIcon {
    color: #9f7aea;
    font-size: 1.5rem;
    flex-shrink: 0;
}

.discussionsIcon {
    color: #9f7aea;
    font-size: 1.5rem;
    flex-shrink: 0;
}

.topicTitle {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
    line-height: 1.4;
}

.topicDescription {
    font-size: 1.0rem;
    line-height: 1.6;
    color: #cbd5e0;
    margin-bottom: 1.5rem;
    letter-spacing: 0.015em;
    font-weight: 300;
}

.excerptsSectionTitle {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0;
    color: #f7fafc;
}

.excerptTableContainer {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;
}

.loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 200px;
}

.noResults {
    color: #a0aec0;
    background-color: #2d3748;
    border-radius: 0.5rem;
    text-align: left;
    padding: 1rem;
    margin: 0;
    display: block;
}

.error {
    background-color: #2d3748;
    color: #fc8181;
    padding: 1rem;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
}

.overlayContent {
    position: relative;
    padding: 20px;
    border-radius: 8px;
    max-height: 90%;
    overflow-y: auto;
}

.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: rgba(171, 86, 252, 0.8);
    color: white;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: opacity 0.3s ease, background-color 0.3s ease;
    z-index: 1006;
}

.closeButton:hover {
    opacity: 1;
    background-color: rgba(171, 86, 252, 1);
}

.header {
    text-align: center;
    margin-bottom: 3rem;
}

.title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 1rem;
}

.description {
    font-size: 1.125rem;
    color: #cbd5e0;
    max-width: 600px;
    margin: 0 auto;
}

.playButton {
    padding: 0.5rem 1rem;
    color: white;
    background-color: #ab56fc;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.2s;
    display: inline-flex;
    align-items: center;
    margin-bottom: 1rem;
    align-self: flex-start;
}

.playButton:hover {
    background-color: #553c9a;
}

.buttonIcon {
    margin-right: 0.5rem;
    width: 1em;
    height: 1em;
}

@media (max-width: 768px) {
    .mainContainer {
        padding: 1rem;
    }

    .excerptTableContainer {
        margin: 0 -1rem;
        width: calc(100% + 2rem);
    }

    .excerptsSectionTitle {
        font-size: 1.25rem;
    }

    .topicTitle {
        font-size: 1.25rem;
    }
    
    .topicDescription {
        font-size: 1.0rem;
    }

    .closeButton {
        width: 32px;
        height: 32px;
        font-size: 1.25rem;
    }

    .title {
        font-size: 2rem;
    }

    .description {
        font-size: 1rem;
    }

    .playButton {
        margin-left: 1rem;
    }

    .section {
        padding: 1rem;
        height: calc(100vh - 200px);
        margin-top: 1rem;
    }
}

@media (min-width: 768px) {
    .mainContainer {
        padding: 2rem;
    }
}

.toastSuccess {
    background-color: #1a202c !important;
    color: white !important;
}

.toastError {
    background-color: #1a202c !important;
    color: #fc8181 !important;
}

.customToast {
    background-color: #1a202c !important;
    color: white !important;
}

.customProgress {
    background: #553c9a !important;
} 