.container {
  position: relative;
  overflow-x: hidden;
  background-color: #2d3748;
  max-height: 600px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.container.loading {
  max-height: 400px;
}

.wrapper {
  overflow-y: auto;
  flex-grow: 1;
  position: relative;
  scrollbar-width: thin;
  scrollbar-color: #4a5568 #2d3748;
  min-height: 200px;
  display: flex;
  flex-direction: column;
}

.loadingWrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(45, 55, 72, 0.9);  /* matches container bg with slight transparency */
  z-index: 10;
}

.wrapper::-webkit-scrollbar {
  width: 8px;
}

.wrapper::-webkit-scrollbar-track {
  background: #2d3748;
  border-radius: 4px;
}

.wrapper::-webkit-scrollbar-thumb {
  background: #4a5568;
  border-radius: 4px;
}

.wrapper::-webkit-scrollbar-thumb:hover {
  background: #718096;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-bottom: 1px solid #4a5568;
  align-items: flex-start;
  padding-bottom: 16px;
}

.title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #f7fafc;
}

.list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.listItem {
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #4a5568;
}

.episodeLink {
  display: flex;
  gap: 16px;
  text-decoration: none;
  color: inherit;
}

.imageContainer {
  min-width: 240px;
  max-width: 240px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.playOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.episodeLink:hover .playOverlay {
  opacity: 1;
}

.playIcon {
  color: white;
  font-size: 2rem;
}

.episodeLink:hover .image {
  transform: scale(1.05);
}

.content {
  flex: 1;
  min-width: 0;
}

.episodeTitle {
  color: #f7fafc;
  margin: 0 0 8px 0;
  font-size: 1.1rem;
  transition: color 0.2s ease;
}

.episodeLink:hover .episodeTitle {
  color: #c084fc;
}

.date {
  color: #a0aec0;
  font-size: 0.9rem;
  margin: 0 0 12px 0;
  display: flex;
  align-items: center;
  gap: 6px;
}

.calendarIcon {
  font-size: 0.9rem;
}

.guests {
  display: flex;
  gap: 8px;
}

.paginationWrapper {
  padding: 16px;
  border-top: 1px solid #4a5568;
  background-color: #2d3748;
}

.guestNames {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  color: #a0aec0;
  font-size: 0.9rem;
}

.guestLink {
  color: #c084fc;
  text-decoration: none;
  transition: color 0.2s ease;
}

.guestLink:hover {
  color: #553c9a;
}

.guestSeparator {
  color: #4a5568;
}

.button {
  padding: 8px 16px;
  background-color: #4a5568;
  color: #f7fafc;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.button:hover {
  background-color: #718096;
}

.showName {
  color: #a0aec0;
  font-size: 0.9rem;
  margin: 0 0 8px 0;
  font-style: italic;
}

@media (max-width: 768px) {
  .episodeLink {
    flex-direction: column;
    gap: 12px;
  }

  .imageContainer {
    min-width: 100%;
    max-width: 100%;
    aspect-ratio: 16 / 9;
    height: auto;
  }
  
  .image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .listItem {
    padding: 12px;
  }

  .content {
    width: 100%;
  }
}
