.mainContainer {
  max-width: 1280px;
  margin: 0 auto;
  padding: 1rem;
}

.section {
  margin-top: 2rem;
  background-color: #2d3748;
  border-radius: 0.5rem;
  padding: 2rem;
  color: #ffffff;
}

.titleContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.trendingIcon {
  color: #9f7aea;
  font-size: 1.5rem;
  flex-shrink: 0;
  animation: pulse 2s infinite;
}

.discussionsIcon {
  font-size: 1.5rem;
  flex-shrink: 0;
}

@keyframes pulse {
  0% { opacity: 0.6; }
  50% { opacity: 1; }
  100% { opacity: 0.6; }
}

.topicTitle {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
  line-height: 1.4;
}

.topicDescription {
  font-size: 1.0rem;
  line-height: 1.6;
  color: #cbd5e0;
  margin-bottom: 1.5rem;
  letter-spacing: 0.015em;
  font-weight: 300;
}

.topicTime {
  display: flex;
  align-items: center;
  color: #718096;
  font-size: 0.9rem;
  margin-top: 0;
}

.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
    padding-top: 1rem;
    border-top: 1px solid #4a5568;
    background: rgba(0, 0, 0, 0.2);
    margin: 2rem -2rem -2rem -2rem;
    padding: 1rem 2rem;
    border-radius: 0 0 0.5rem 0.5rem;
}

.xLink {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    color: #ffffff;
    text-decoration: none;
    background-color: black;
    padding: 0.5rem 1rem;
    border-radius: 9999px;
    font-size: 0.9rem;
    transition: background-color 0.2s ease;
    background: rgba(0, 0, 0, 0.5);
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.xLink:hover {
    background-color: #1a91da;
    background: #1DA1F2;
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(29, 161, 242, 0.3);
}

.xIcon {
    font-size: 1rem;
}

@media (min-width: 768px) {
  .mainContainer {
    padding: 2rem;
  }
} 

.excerptsSectionTitle {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0;
  color: #f7fafc;
}

.topicDescription::after {
  content: '';
  display: block;
  height: 1px;
  width: 100%;
  /* margin: 0.75rem 0; */
  /* background: linear-gradient(90deg, rgba(255,255,255,0.2) 0%, transparent 100%); */
}

.excerptTableContainer {
  width: 100%;
  overflow-x: auto;
}

.noResults {
  color: #a0aec0;
  text-align: center;
  padding: 2rem;
  background-color: #2d3748;
  border-radius: 0.5rem;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.overlayContent {
  position: relative;
  padding: 20px;
  border-radius: 8px;
  max-height: 90%;
  overflow-y: auto;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(171, 86, 252, 0.8);
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.3s ease, background-color 0.3s ease;
  z-index: 1006;
}

.closeButton:hover {
  opacity: 1;
  background-color: rgba(171, 86, 252, 1);
}

.error {
  background-color: #2d3748;
  color: #fc8181;
  padding: 1rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

.descriptionContainer {
  position: relative;
  cursor: pointer;
  padding: 1rem 0;
  transition: all 0.3s ease;
}

.descriptionContainer:not(.expanded) .topicDescription {
  /* Only apply truncation on mobile */
  @media (max-width: 768px) {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.descriptionContainer.expanded .topicDescription {
  display: block;
}

.expandButton {
  display: none; /* Hide by default on desktop */
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.5rem;
  font-size: 1.25rem;
  font-weight: 500;
  color: #cbd5e0;
  
  /* Only show on mobile */
  @media (max-width: 768px) {
    display: flex;
  }
  transition: all 0.2s ease;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

/* .descriptionContainer:hover .expandButton {
  color: #cbd5e0;
} */

.topicDescription {
  font-size: 1.125rem;
  line-height: 1.6;
  color: #cbd5e0;
  margin: 0;
  transition: all 0.3s ease;
}

@media (max-width: 768px) {
  .excerptTableContainer {
    margin: 0 -1rem;
    width: calc(100% + 2rem);
  }

  .closeButton {
    width: 32px;
    height: 32px;
    font-size: 1.25rem;
  }

  .excerptsSectionTitle {
    font-size: 1.25rem;
  }

  .topicTitle {
    font-size: 1.25rem;
  }
  
  .topicDescription {
    font-size: 1.0rem;
  }

  .expandButton {
    font-size: 1.0rem;
}
} 

.expandButton:hover {
  background: rgba(255, 255, 255, 0.1);
}

.topicTitleContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-decoration: none;
  color: inherit;
  position: relative;
  padding-right: 2rem;
}

.topicTitleContainer .hoverIcon {
  position: absolute;
  right: 0;
  opacity: 0;
  transform: translateX(-10px);
  transition: all 0.3s ease;
}

.topicTitleContainer:hover .hoverIcon {
  opacity: 1;
  transform: translateX(0);
}
