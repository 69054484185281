.mainContainer {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
}

.section {
    margin-bottom: 3rem;
}

.browseContainer {
    background-color: #2d3748;
    border-radius: 0.5rem;
    padding: 1rem;
    margin-top: 1.5rem;
    overflow: hidden;
}

.browseHeader {
    color: #ffffff;
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
    font-weight: bold;
}

.browseLoadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100px;
    max-height: 100px;
  }

@media (min-width: 768px) {
    .mainContainer {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    .browseHeader {
        font-size: 1.875rem;
    }
}

.browseList {
    display: flex;
    overflow-x: auto;
    gap: 1.25rem;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    padding: 0.5rem 0.5rem 1rem 0.5rem;
}

/* Customize scrollbar for WebKit browsers */
.browseList::-webkit-scrollbar {
    height: 8px;
}

.browseList::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
}

.browseList::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.browseList::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* For Firefox */
.browseList {
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
}

.browseItem {
    flex: 0 0 auto;
    width: 200px;
    display: flex;
    flex-direction: column;
    transition: transform 0.3s ease;
    cursor: pointer;
    text-decoration: none;
    color: inherit;
}

.browseItem:hover {
    transform: scale(1.05);
}

.browseImage {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 8px 8px 0 0;
}

.browseTitle {
    background-color: #6a329f;
    border-radius: 0 0 8px 8px;
    padding: 0.5rem;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loadMoreItem {
    cursor: pointer;
    background-color: #3d4756;
    border: 2px dashed #6a329f;
    border-radius: 8px;
    transition: all 0.3s ease;
    min-height: 200px;
}

.loadMoreItem:hover {
    background-color: #4a5568;
    border-color: #ab56fc;
}

.loadMoreContent {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    padding: 1rem;
}

.loadMoreIcon {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
}

.loadMoreText {
    font-size: 1rem;
    font-weight: bold;
}

.trendingItem {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    transition: transform 0.3s ease;
    cursor: pointer;
    text-decoration: none;
    color: inherit;
    background-color: #3d4756;
    border-radius: 8px;
    transition: transform 0.3s ease, background-color 0.3s ease;
    pointer-events: auto;
    border: 1px solid rgba(255, 255, 255, 0.1);
    flex: 1 0 auto;
}

.trendingItem:hover {
    transform: translateY(-4px);
    background-color: #4a5568;
    border-color: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.trendingContent {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    min-height: min-content;
    position: relative;
    padding-top: 2.5rem;
}

.trendingTitleContainer {
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;
    position: relative;
    padding-left: 1.75rem;
    width: fit-content;
}

.trendingIcon {
    position: absolute;
    left: 0;
    top: 0.2rem;
    font-size: 1rem;
    flex-shrink: 0;
    color: #9f7aea;
}

.trendingTitle {
    color: #ffffff;
    font-size: 1.125rem;
    font-weight: 600;
    margin: 0;
    line-height: 1.5;
    word-wrap: break-word;
    hyphens: auto;
}

.trendingDescription {
    color: #cbd5e0;
    font-size: 0.9rem;
    margin: 0;
    line-height: 1.5;
}

.trendingTime {
    color: #a0aec0;
    font-size: 0.8rem;
    position: absolute;
    top: 0.75rem;
    left: 1.5rem;
    opacity: 0.8;
}

.trendingList {
    display: flex;
    gap: 1.25rem;
    padding: 0.5rem;
    align-items: stretch;
}

.trendingList::-webkit-scrollbar {
    display: none;
}

/* Enable pointer events for items */
.trendingItem {
    pointer-events: auto;
}

/* Remove the hover state since we want it always scrollable */
.trendingList:hover {
    pointer-events: auto;
    overflow-x: auto;
}

.clickableHeader {
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem;
    border-radius: 0.375rem;
}

.clickableHeader:hover {
    background-color: #3d4756;
}

.headerChevron {
    font-size: 1rem;
    opacity: 0.7;
    transition: all 0.2s ease;
}

.clickableHeader:hover .headerChevron {
    opacity: 1;
}

.topicsWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    padding: 0.5rem;
    overflow-y: auto;
    max-height: 150px;
    align-content: flex-start;
}

@media (max-width: 768px) {
    .topicsWrapper {
        max-height: 150px;
    }
}

.topic {
    display: inline-flex;
    align-items: center;
    border-radius: 16px;
    cursor: pointer;
    transition: opacity 0.2s ease;
    user-select: none;
}

.topic:hover {
    opacity: 0.8;
}

.topicCount {
    margin-left: 0.5rem;
    padding-left: 0.5rem;
    border-left: 1px solid rgba(255, 255, 255, 0.3);
    font-size: 0.8rem;
}

/* Add scrollbar styles */
.topicsWrapper::-webkit-scrollbar {
    width: 8px;
}

.topicsWrapper::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
}

.topicsWrapper::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
}

.topicsWrapper::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.3);
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
}

.overlayContent {
    position: relative;
    padding: 20px;
    border-radius: 8px;
    max-height: 90%;
    overflow-y: auto;
}

.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: rgba(171, 86, 252, 0.8);
    color: white;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: opacity 0.3s ease, background-color 0.3s ease;
    z-index: 1006;
}

.closeButton:hover {
    opacity: 1;
    background-color: rgba(171, 86, 252, 1);
}

.closeButton:not(:hover) {
    opacity: 0.8;
}

@media (max-width: 768px) {
    .closeButton {
        width: 32px;
        height: 32px;
        font-size: 1.25rem;
    }
}
