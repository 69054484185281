.ingestionState {
  margin: 0;
}

.counts {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  color: #666;
}

.countInfo {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.infoButton {
  background: none;
  border: none;
  padding: 0;
  color: #ab56fc;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: color 0.2s ease;
}

.infoButton:hover {
    color: #553c9a;
}

.percentage {
  font-weight: 500;
}

.progressBar {
  width: 100%;
  height: 8px;
  background-color: #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
}

.progress {
  height: 100%;
  background-color: #4CAF50;
  transition: width 0.3s ease;
}
