.tableContainer {
  position: relative;
  overflow-x: auto;
  margin-top: 20px;
  background-color: #2d3748;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-height: 600px;
  display: flex;
  flex-direction: column;
}

.tableWrapper {
  overflow-y: auto;
  flex-grow: 1;
  position: relative;
  scrollbar-width: thin;
  scrollbar-color: #4a5568 #2d3748;
  padding-bottom: 40px;
}

/* Webkit scrollbar styling */
.tableWrapper::-webkit-scrollbar {
  width: 8px;
}

.tableWrapper::-webkit-scrollbar-track {
  background: #2d3748;
  border-radius: 4px;
}

.tableWrapper::-webkit-scrollbar-thumb {
  background: #4a5568;
  border-radius: 4px;
}

.tableWrapper::-webkit-scrollbar-thumb:hover {
  background: #718096;
}

.table {
  width: 100%;
  border-collapse: collapse;
  color: white;
}

.table th,
.table td {
  padding: 16px;
  text-align: left;
  border-bottom: 1px solid #4a5568;
  vertical-align: top;
}

.table th {
  font-weight: 600;
}

.thumbnailContainer {
  position: relative;
  width: 160px;
  height: 90px;
  cursor: pointer;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.thumbnailImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
  transition: transform 0.3s ease;
}

.thumbnailContainer:hover .thumbnailImage {
  transform: scale(1.05);
}

.playOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.thumbnailContainer:hover .playOverlay {
  opacity: 1;
}

.playIcon {
  color: white;
  font-size: 1.5rem;
}

.showLink {
  color: #ab56fc;
  cursor: pointer;
  transition: color 0.2s ease;
}

.showLink:hover {
  color: #9645e0;
}

.guestLink {
  color: #a0aec0;
  cursor: pointer;
  transition: color 0.2s ease;
}

.guestLink:hover {
  color: #9645e0;
}

.tagsCell {
  max-width: 1000px;
}

.titleContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
}

.title {
  color: #f7fafc;
  cursor: pointer;
  transition: color 0.2s ease;
  font-weight: 600;
  font-size: 1.1rem;
}

.title:hover {
  color: #9645e0;
}

.starIcon {
  color: #FFD700;
  font-size: 1.1rem;
}

.excerptItem:has(.starIcon) {
  background: linear-gradient(90deg, rgba(255, 215, 0, 0.1) 0%, rgba(45, 55, 72, 0) 100%);
}

.excerptItem:has(.starIcon):hover {
  background: linear-gradient(90deg, rgba(255, 215, 0, 0.15) 0%, rgba(45, 55, 72, 0) 100%);
}

.excerptItem:has(.starIcon) .title {
  color: #FFD700;
}

.excerptItem:has(.starIcon) .title:hover {
  color: #9645e0;
}

.contextCell {
  max-width: 300px;
  position: relative;
}

.contextContent {
  color: #cbd5e0;
  font-size: 0.95rem;
  line-height: 1.6;
  padding: 0.5rem 0;
}

@media (max-width: 768px) {
  .table th,
  .table td {
    padding: 8px;
    font-size: 14px;
  }

  .thumbnailContainer {
    width: 60px;
    height: 40px;
  }

  .tagsCell {
    max-width: 150px;
  }

  .contextCell {
    max-width: 150px;
  }
}

.tabContainer {
  position: sticky;
  top: 0;
  background-color: #2d3748;
  z-index: 1;
  display: flex;
  gap: 1px;
  padding: 1px;
  border-radius: 6px;
  margin: 16px 16px 24px 16px;
}

.tabButton {
  flex: 1;
  padding: 10px 24px;
  background-color: #2d3748;
  border: none;
  color: #a0aec0;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 0.9rem;
  font-weight: 500;
  letter-spacing: 0.3px;
}

.tabButton:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.tabButton:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.tabButton:hover {
  background-color: #4a5568;
  color: #f7fafc;
}

.activeTab {
  background-color: #4a5568;
  color: #f7fafc;
}

@media (max-width: 768px) {
  .tabButton {
    padding: 6px 12px;
    font-size: 0.8rem;
  }
}

.tableContainer::after {
  content: '';
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px;
  background: linear-gradient(to top, #2d3748 0%, transparent 100%);
  pointer-events: none;
  z-index: 1;
}

.starCell {
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.excerptsList {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.excerptItem {
  padding: 16px;
  border-bottom: 1px solid #4a5568;
}

.excerptContent {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

.textContent {
  flex: 1;
  min-width: 0;
}
