.container {
  color: #f3f4f6;
  font-family: sans-serif;
}

.main {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;
  text-align: center;
}

.title {
  font-size: 2.25rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.content {
  max-width: 42rem;
  margin: 0 auto;
  font-size: 1.125rem;
  line-height: 1.75;
}

.paragraph {
  margin-bottom: 1rem;
}

.link {
  color: #c084fc;
  transition: color 0.3s;
}

.link:hover {
  color: #ddd6fe;
}

@media (min-width: 768px) {
  .main {
    padding: 4rem 1rem;
  }

  .title {
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }

  .content {
    font-size: 1.25rem;
  }
}
