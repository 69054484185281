.playerContainer {
  max-width: 800px;
  margin: 0 auto;
  background-color: #1a202c;
  color: #f7fafc;
  font-family: Arial, sans-serif;
}

.playerMainContent {
  margin-bottom: 0.0rem;
}

.episodeHeader {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: stretch;
  margin-bottom: 2rem;
}

.episodeInfo {
  width: 100%;
  text-align: left;
}

.excerptTitle {
  color: #f7fafc;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.episodeTitle {
  text-align: left;
  color: #ab56fc;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.showTitle {
  font-size: 1rem;
  color: #a0aec0;
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
}

.showTitleLink {
  text-decoration: none;
  color: inherit;
  transition: color 0.2s ease-in-out;
}

.showTitleLink:hover {
  color: #ffffff;
}

.publishedDate {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 0.9rem;
  color: #a0aec0;
  margin-bottom: 0.5rem;
}

.calendarIcon {
  font-size: 14px;
}

.personList {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 1rem 0;
}

.personListItems {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-grow: 1;
}

.episodeImage {
  width: 100%;
  height: auto;
  border-radius: 0.5rem;
  display: block;
}

.audioPlayerOverlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.currentlyPlayingContainer {
  margin-top: 1.5rem;
  padding: 1rem;
  background-color: #2d3748;
  border-radius: 0.5rem;
}

.sponsorsTitle,
.currentlyPlayingTitle {
  color: #a0aec0;
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
  font-weight: bold;
}

@media (min-width: 768px) {
  .episodeHeader {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 2rem;
  }

  .episodeInfo {
    flex: 1;
    order: 1;
  }

  .personList {
    justify-content: flex-start;
  }
}

.showImageContainer {
  margin-bottom: 0.5rem;
}

.episodeTitleButton {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  color: #ab56fc;
  text-decoration: none;
  transition: color 0.3s ease;
  text-align: left;
  display: inline-block;
  width: 100%;
}

.episodeTitleButton:hover {
  color: #553c9a;
  text-decoration: underline;
}

.buttonSection {
  flex-shrink: 0;
  display: flex;
  gap: 8px;
}

.button {
  padding: 0.5rem 1rem;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shareButton {
  composes: button;
  background-color: #4a5568;
  white-space: nowrap;
}

.buttonIcon {
    margin-right: 8px;
  }

.shareButton:hover {
  background-color: #2d3748;
}

.toastSuccess {
  background-color: #1a202c !important;
  color: white !important;
}

.queueButton {
  composes: button;
  background-color: #553c9a;
  white-space: nowrap;
}

.queueButton:hover {
  background-color: #44337a;
}

.toastError {
  background-color: #1a202c !important;
  color: #fc8181 !important;
}

.episodeImageSection {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.queueButtonBelowImage {
  composes: button;
  background-color: #553c9a;
  white-space: nowrap;
  width: 100%;
  margin-top: 0.5rem;
  transition: background-color 0.3s ease;
}

.queueButtonBelowImage:hover {
  background-color: #44337a;
}

.queueButtonSuccess {
  background-color: #48bb78 !important;
  cursor: default;
}

.queueButtonSuccess:hover {
  background-color: #48bb78 !important;
}

.spinningIcon {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.queueButtonBelowImage:disabled {
  opacity: 0.8;
  cursor: not-allowed;
}
