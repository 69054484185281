.resultItem {
  margin-bottom: 20px;
  border: 1px solid #333;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  align-items: flex-start;
  background-color: #252525;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
}

.resultItem:hover {
  box-shadow: 0 6px 12px rgba(0,0,0,0.2);
  transform: translateY(-2px);
}

.iconButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ab56fc;
  transition: color 0.3s ease;
}

.iconButton:hover {
  color: #c278fd;
}

.personIcon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 15px;
}

.contentWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.resultText {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  color: #f0f0f0;
  cursor: pointer;
  transition: color 0.3s ease;
}

.resultText:hover,
.resultTextPlaying {
  color: #c278fd;
}

.metadataWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: #b0b0b0;
  font-size: 14px;
}

.iconWrapper {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #f0f0f0;
}

.summaryWrapper {
  display: flex;
  align-items: flex-start;
  gap: 5px;
  background-color: #333;
  border-radius: 8px;
  padding: 10px;
}

.summaryText {
  display: inline-block;
  margin-top: -3px;
  line-height: 1.4;
}

.tagsWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 10px;
}

.tag {
  background-color: #b0b0b0;
  color: black;
  padding: 4px 10px;
  border-radius: 16px;
  font-size: 0.9em;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.tag:hover {
  background-color: #c278fd;
  transform: translateY(-1px);
}

.listenButton {
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
}

.listenButton:hover {
  background-color: #45a049;
  transform: scale(1.1);
}

.buttonGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-left: 15px;
}

.circleButton {
  color: white;
  border: none;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
}

.circleButton:hover {
  transform: scale(1.1);
}
