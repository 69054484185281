.showContainer {
  min-height: 100vh;
  background-color: #1a202c;
  color: #f7fafc;
  font-family: Arial, sans-serif;
}

.mainContent {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.showHeader {
  width: 100%;
  display: grid;
  gap: 2rem;
  margin-bottom: 2rem;
}

@media (min-width: 768px) {
  .showHeader {
    grid-template-columns: 300px 1fr;
    align-items: start;
  }

  .showImageContainer {
    position: sticky;
    top: 2rem;
    align-self: start;
  }

  .mainContent {
    padding: 2rem;
  }
}

.showImageContainer {
  height: fit-content;
  max-width: 300px;
  margin: 0;
  position: sticky;
  top: 2rem;
  align-self: start;
}

@media (max-width: 767px) {
  .showImageContainer {
    position: static;
    max-width: 200px;
    margin: 0 auto;
  }
}

.showImage {
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: 8px;
}

.showInfo {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.fanSiteText {
  font-size: 1rem;
  color: #a0aec0;
  margin: 0;
  font-style: italic;
}

.showTitle {
  font-size: 2rem;
  font-weight: bold;
  margin: 0;
}

.showDescription {
  font-size: 1rem;
  color: #a0aec0;
  line-height: 1.5;
  margin: 0;
}

.sectionTitle {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #f7fafc;
}

.sectionContainer {
  width: 100%;
  background-color: #2d3748;
  border-radius: 0.5rem;
  padding: 1rem;
  margin-bottom: 1rem;
  box-sizing: border-box;
  overflow: hidden;
}

@media (min-width: 768px) {
  .sectionContainer {
    padding: 1.5rem;
    margin-bottom: 2rem;
  }
}

.topicsSection {
  width: 100%;
  background-color: #2d3748;
  border-radius: 0.5rem;
  padding: 1.5rem;
  margin-bottom: 2rem;
  box-sizing: border-box;
}

.guestsSection,
.episodesSection {
  composes: sectionContainer;
}

.hostsSection {
  margin: 0;
}

.hostsList,
.episodesList {
  list-style-type: none;
  padding: 0;
}

.hostItem,
.episodeItem {
  margin-bottom: 1rem;
}

.episodeTitle {
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 0.25rem;
}

.episodeDate {
  font-size: 0.9rem;
  color: #a0aec0;
}

@media (min-width: 768px) {
  .showHeader {
    flex-direction: row;
    align-items: flex-start;
  }

  .showImageContainer {
    margin-right: 2rem;
    margin-bottom: 0;
  }

  .showInfo {
    text-align: left;
    flex-grow: 1;
  }
}

.buttonSection {
  margin: 0;
  display: flex;
  gap: 1rem;
  padding-top: 1rem;
}

.button {
  padding: 0.5rem 1rem;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.playFeedButton {
  composes: button;
  background-color: #ab56fc;
}

.playFeedButton:hover {
  background-color: #553c9a;
}

.showSiteButton {
  composes: button;
  background-color: #28a745;
}

.showSiteButton:hover {
  background-color: #218838;
}

.buttonIcon {
  margin-right: 0.5rem;
  width: 1em;
  height: 1em;
}

.shareButton {
  composes: button;
  background-color: #4a5568;
}

.shareButton:hover {
  background-color: #2d3748;
}

.toastSuccess {
  background-color: #1a202c !important;
  color: white !important;
}

.guestsList {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-bottom: 1rem;
}

.guestItem {
  flex: 0 0 auto;
  margin-right: 1rem;
}

.hostsSection {
  margin-bottom: 1rem;
}

.hostsList {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin: 0;
}

.hostItem {
  margin: 0;
}

.episodesHeader {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}

.typeToggle {
  padding: 0.5rem 1rem;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background-color: var(--background-secondary);
  color: var(--text-primary);
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.typeToggle:hover {
  background-color: var(--background-hover);
}

.clickableTitle {
  cursor: pointer;
  transition: opacity 0.2s;
}

.clickableTitle:hover {
  opacity: 0.8;
}

/* Add these new styles for the trending section */
.section {
    margin-bottom: 3rem;
}

.browseContainer {
    background-color: #2d3748;
    border-radius: 0.5rem;
    padding: 1rem;
    margin-top: 1.5rem;
    overflow: hidden;
}

.browseHeader {
    color: #ffffff;
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
    font-weight: bold;
}

.browseLoadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100px;
    max-height: 100px;
}

.browseList {
    display: flex;
    overflow-x: auto;
    gap: 1.25rem;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    padding: 0.5rem 0.5rem 1rem 0.5rem;
}

.browseList::-webkit-scrollbar {
    height: 8px;
}

.browseList::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
}

.browseList::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.browseList::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.browseList {
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
}

.trendingItem {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    transition: transform 0.3s ease;
    cursor: pointer;
    text-decoration: none;
    color: inherit;
    background-color: #3d4756;
    border-radius: 8px;
    transition: transform 0.3s ease, background-color 0.3s ease;
    pointer-events: auto;
    border: 1px solid rgba(255, 255, 255, 0.1);
    flex: 1 0 auto;
}

.trendingItem:hover {
    transform: translateY(-4px);
    background-color: #4a5568;
    border-color: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.trendingContent {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    min-height: min-content;
    position: relative;
    padding-top: 2.5rem;
}

.trendingTitleContainer {
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;
    position: relative;
    padding-left: 1.75rem;
    width: fit-content;
}

.trendingIcon {
    position: absolute;
    left: 0;
    top: 0.2rem;
    font-size: 1rem;
    flex-shrink: 0;
    color: #9f7aea;
}

.trendingTitle {
    color: #ffffff;
    font-size: 1.125rem;
    font-weight: 600;
    margin: 0;
    line-height: 1.5;
    word-wrap: break-word;
    hyphens: auto;
}

.trendingTime {
    color: #a0aec0;
    font-size: 0.8rem;
    position: absolute;
    top: 0.75rem;
    left: 1.5rem;
    opacity: 0.8;
}

.trendingList {
    display: flex;
    gap: 1.25rem;
    padding: 0.5rem;
    align-items: stretch;
}

.trendingList::-webkit-scrollbar {
    display: none;
}

.trendingItem {
    pointer-events: auto;
}

.clickableHeader {
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem;
    border-radius: 0.375rem;
}

.clickableHeader:hover {
    background-color: #3d4756;
}

.headerChevron {
    font-size: 1rem;
    opacity: 0.7;
    transition: all 0.2s ease;
}

.clickableHeader:hover .headerChevron {
    opacity: 1;
}

@media (min-width: 768px) {
    .browseHeader {
        font-size: 1.875rem;
    }
}

.searchButton {
  composes: button;
  background-color: #4299e1;
}

.searchButton:hover {
  background-color: #3182ce;
}
