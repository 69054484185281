.searchContainer {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.searchForm {
  display: flex;
  margin-bottom: 20px;
}

.searchInput {
  flex-grow: 1;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px 0 0 4px;
  color: #2d3748;
}

.searchButton {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #ab56fc;
  color: white;
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.searchButton:hover {
  background-color: #553c9a;
}

.error {
  background-color: #2d3748;
  color: #fc8181;
  padding: 1rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

.noResults {
  color: #a0aec0;
  text-align: center;
  padding: 2rem;
  background-color: #2d3748;
  border-radius: 0.5rem;
}

/* Responsive design */
@media (max-width: 600px) {
  .searchForm {
    flex-direction: column;
  }

  .searchInput,
  .searchButton {
    width: 100%;
    border-radius: 4px;
  }

  .searchButton {
    margin-top: 10px;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.overlayContent {
  position: relative;
  padding: 20px;
  border-radius: 8px;
  max-height: 90%;
  overflow-y: auto;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(171, 86, 252, 0.8);
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.3s ease, background-color 0.3s ease;
  z-index: 1006;
}

.closeButton:hover {
  opacity: 1;
  background-color: rgba(171, 86, 252, 1);
}

.closeButton:not(:hover) {
  opacity: 0.8;
}

.loadMoreButton {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #ab56fc;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.loadMoreButton:hover {
  background-color: #9645e0;
}

.loadMoreButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.sectionContainer {
  background-color: #2d3748;
  border-radius: 0.5rem;
  padding: 1.5rem;
  margin-bottom: 2rem;
  max-width: 1280px;
  margin: 2rem auto;
  box-sizing: border-box;
}

.selectedShows {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  align-items: center;
  min-height: 44px;
  padding: 0.5rem;
  background: rgba(26, 26, 26, 0.6);
  border-radius: 0.5rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.selectedShow {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: #4a5568;
  border-radius: 2rem;
  color: #f7fafc;
  transition: background-color 0.2s;
}

.selectedShow:hover {
  background-color: #2d3748;
}

.selectedShowTitle {
  font-size: 14px;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
}

.removeShowButton {
  background: none;
  border: none;
  color: #f7fafc;
  font-size: 1.2rem;
  padding: 0 0.25rem;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.2s;
}

.removeShowButton:hover {
  opacity: 1;
}

.addShowButton {
  padding: 0.5rem 1rem;
  background-color: #4a5568;
  border: none;
  border-radius: 2rem;
  color: white;
  cursor: pointer;
  transition: background-color 0.2s;
  font-weight: 500;
}

.addShowButton:hover {
  background-color: #553c9a;
}

@media (max-width: 600px) {
  .sectionContainer {
    margin: 15px auto;
    padding: 0 15px;
    width: 95%;
  }

  .excerptsSectionContainer {
    width: 95%;
  }

  .excerptTableContainer {
    width: 95%;
  }

  .selectedShows {
    padding: 6px;
    gap: 8px;
  }

  .selectedShow {
    padding: 4px 10px;
  }

  .selectedShowTitle {
    max-width: 120px;
    font-size: 13px;
  }
}

.topicsContainer {
  margin: 20px auto;
  padding: 20px;
  background: #1a1a1a;
  border-radius: 12px;
  max-width: 800px;
  min-height: 100px;
  max-height: 300px;
  position: relative;
  overflow: hidden;
}

.selectedTopics {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.selectedTopic {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: #4a5568;
  border-radius: 2rem;
  color: #f7fafc;
}

.selectedTopicName {
  font-size: 14px;
}

.removeTopicButton {
  background: none;
  border: none;
  color: #f7fafc;
  font-size: 1.2rem;
  padding: 0 0.25rem;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.2s;
}

.removeTopicButton:hover {
  opacity: 1;
}

.headerTitle {
  font-size: 1.5rem;
  font-weight: bold;
  color: #f7fafc;
  margin-bottom: 1rem;
}

.pageTitle {
  font-size: 2rem;
  font-weight: bold;
  color: #f7fafc;
  margin: 2rem auto;
  max-width: 1200px;
  text-align: center;
}

.excerptTableContainer {
  width: 100%;
  overflow-x: auto;
}

.excerptsLoadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  max-height: 300px;
}

/* Update existing responsive styles */
@media (max-width: 768px) {
  .sectionContainer {
    margin: 1rem auto;
    padding: 1rem;
    border-radius: 0.375rem;
  }

  .pageTitle {
    font-size: 1.5rem;
    margin: 1rem auto;
    padding: 0 1rem;
  }

  .headerTitle {
    font-size: 1.25rem;
    padding: 0 0.25rem;
  }

  .selectedShows {
    padding: 0.375rem;
    gap: 0.375rem;
  }

  .selectedShow {
    padding: 0.375rem 0.75rem;
  }

  .selectedShowTitle {
    max-width: 120px;
    font-size: 0.875rem;
  }

  .addShowButton {
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
  }

  .selectedTopics {
    gap: 0.375rem;
  }

  .selectedTopic {
    padding: 0.375rem 0.75rem;
  }

  .selectedTopicName {
    font-size: 0.875rem;
  }

  .removeTopicButton,
  .removeShowButton {
    font-size: 1rem;
    padding: 0 0.125rem;
  }

  .searchContainer {
    padding: 0 1rem;
  }

  .error,
  .noResults {
    padding: 0.75rem;
    margin-bottom: 0.75rem;
    font-size: 0.875rem;
  }

  .excerptTableContainer {
    margin: 0 -1rem;  /* Negative margin to allow table to stretch full width */
    width: calc(100% + 2rem);
  }

  .closeButton {
    width: 32px;
    height: 32px;
    font-size: 1.25rem;
  }
}

/* Add styles for very small screens */
@media (max-width: 480px) {
  .sectionContainer {
    padding: 0.75rem;
  }

  .pageTitle {
    font-size: 1.25rem;
  }

  .selectedShowTitle {
    max-width: 100px;
  }

  .selectedShow {
    padding: 0.25rem 0.5rem;
  }

  .addShowButton {
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
  }
}

.selectedPersons {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  align-items: center;
  min-height: 44px;
  padding: 0.5rem;
  background: rgba(26, 26, 26, 0.6);
  border-radius: 0.5rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.selectedPerson {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: #4a5568;
  border-radius: 2rem;
  color: #f7fafc;
  transition: background-color 0.2s;
}

.selectedPersonLink {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.selectedPersonLink:hover {
  opacity: 0.8;
}

.selectedPerson:hover {
  background-color: #2d3748;
}

.selectedPersonName {
  font-size: 14px;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
}

.removePersonButton {
  background: none;
  border: none;
  color: #f7fafc;
  font-size: 1.2rem;
  padding: 0 0.25rem;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.2s;
}

.removePersonButton:hover {
  opacity: 1;
}

.addPersonButton {
  padding: 0.5rem 1rem;
  background-color: #4a5568;
  border: none;
  border-radius: 2rem;
  color: white;
  cursor: pointer;
  transition: background-color 0.2s;
  font-weight: 500;
}

.addPersonButton:hover {
  background-color: #553c9a;
}

/* Update existing responsive styles */
@media (max-width: 768px) {
  .selectedPerson {
    padding: 0.375rem 0.75rem;
  }

  .selectedPersonName {
    max-width: 120px;
    font-size: 0.875rem;
  }

  .addPersonButton {
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
  }

  .removePersonButton {
    font-size: 1rem;
    padding: 0 0.125rem;
  }
}

@media (max-width: 480px) {
  .selectedPersonName {
    max-width: 100px;
  }

  .selectedPerson {
    padding: 0.25rem 0.5rem;
  }

  .addPersonButton {
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
  }
}

.selectedShowLink {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.selectedShowLink:hover {
  opacity: 0.8;
}

.excerptsSectionContainer {
  background-color: #2d3748;
  border-radius: 0.5rem;
  padding: 1rem;
  margin-bottom: 2rem;
  margin: 2rem auto;
  box-sizing: border-box;
  max-height: 800px;
  max-width: 1280px;
  display: flex;
  flex-direction: column;
  overflow: hidden; /* Prevent content from spilling out */
}

.excerptTableContainer {
  overflow-x: auto;
  flex: 1;
  overflow-y: auto;
  min-height: 0; /* Important for Firefox */
  padding: 0 1rem;
}
