.topicsSection {
    background-color: #2d3748;
    border-radius: 0.5rem;
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 150px;
}

.topicsLoadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50px;
    max-height: 100px;
}

.sectionTitle {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
    color: var(--text-primary);
    flex-shrink: 0;
}

.topicsWrapper {
  display: flex;
  gap: 0.5rem 0.5rem;
  padding: 0.5rem;
  overflow-y: auto;
  flex-wrap: wrap;
  max-height: 200px;
  min-height: 125px;
  align-content: flex-start;
}

.topic {
  display: inline-flex;
  align-items: center;
  border-radius: 16px;
  cursor: pointer;
  transition: opacity 0.2s ease;
  user-select: none;
  white-space: nowrap;
  max-height: fit-content;
  line-height: 1.2;
}

.topic:hover {
  opacity: 0.8;
}

.topicCount {
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  font-size: 0.8rem;
}

/* Customize scrollbar */
.topicsWrapper::-webkit-scrollbar {
  height: 8px;
}

.topicsWrapper::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
}

.topicsWrapper::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
}

.topicsWrapper::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.3);
}

@media (max-width: 480px) {
  .topicsSection {
    margin: 0rem 0;
  }

  .topicsWrapper {
    max-height: 125px;
  }

  .sectionTitle {
    font-size: 1.5rem;
  }

  .topic {
    font-size: 0.8rem;
    padding: 0.2rem 0.6rem;
  }
}
