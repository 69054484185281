.showsListContainer {
  margin-bottom: 2rem;
}

.showsListTitle {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #f7fafc;
}

.showsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 1rem;
}

.showCard {
  text-decoration: none;
  color: inherit;
  transition: transform 0.2s;
}

.showCard:hover {
  transform: scale(1.05);
}

.showImage {
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  border-radius: 0.5rem;
}

.showTitle {
  margin-top: 0.5rem;
  font-size: 0.9rem;
  text-align: center;
  color: #f7fafc;
}
