.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin: 1rem 0;
}

.pageButton {
  padding: 0.5rem 0.75rem;
  border: 1px solid var(--border-color);
  background-color: var(--background-secondary);
  color: var(--text-primary);
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
}

.pageButton:hover:not(.disabled):not(.active) {
  background-color: var(--background-hover);
}

.pageButton.active {
  background-color: var(--primary-color);
  color: white;
  border-color: var(--primary-color);
  font-weight: bold;
  font-size: 1.1rem;
}

.pageButton.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

@media (max-width: 480px) {
  .pagination {
    gap: 0.25rem;
  }

  .pageButton {
    padding: 0.4rem 0.6rem;
    min-width: 2rem;
    font-size: 1rem;
  }
}
