.waitlistBox {
  @apply bg-gray-800 p-6 md:p-8 rounded-lg max-w-md mx-auto mb-12 md:mb-16 shadow-lg border border-purple-500;
  text-align: center;
}

.title {
  @apply text-xl md:text-2xl font-bold mb-3 md:mb-4;
}

.description {
  @apply mb-4 md:mb-6;
}

.button {
  @apply inline-block bg-purple-600 hover:bg-purple-700 px-6 py-3 rounded-md transition-colors duration-300 text-base md:text-lg font-semibold;
}
