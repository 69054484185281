.navBar {
  @apply bg-gray-800 text-white fixed left-0 right-0 z-50;
}

.desktopNav {
  @apply p-4 top-0;
}

.mobileNav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: env(safe-area-inset-bottom);
}

.containerWrapper {
  @apply mx-auto px-4;
  max-width: 1280px;
}

.innerContainer {
  @apply flex justify-between items-center;
}

.logo {
  @apply text-xl font-bold;
}

.desktopLinks {
  @apply space-x-4;
}

.link {
  @apply hover:text-purple-300;
}

.activeLink {
  @apply text-purple-400;
}

.mobileNavContainer {
  @apply flex justify-around items-center h-16;
}

.mobileLink {
  @apply flex flex-col items-center;
}

.mobileIcon {
  @apply text-2xl mb-1;
}

.mobileLinkText {
  @apply text-xs;
}

.mobileTopNav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 1000;
  padding-top: env(safe-area-inset-top);
}

.mobileTopNavContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
}

.mobileBottomNav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 -2px 4px rgba(0,0,0,0.1);
  z-index: 1000;
  padding-bottom: 8px;
  margin-bottom: env(safe-area-inset-bottom);
}
