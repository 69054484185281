.mainContainer {
    max-width: 1280px;
    margin: 0 auto;
    padding: 2rem 1rem;
}

.header {
    text-align: center;
    margin-bottom: 3rem;
}

.title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 1rem;
}

.description {
    font-size: 1.125rem;
    color: #cbd5e0;
    max-width: 600px;
    margin: 0 auto;
}

.loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
}

.topicsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.5rem;
}

.topicCard {
    background-color: #2d3748;
    border-radius: 0.5rem;
    transition: transform 0.3s ease, background-color 0.3s ease;
    text-decoration: none;
    overflow: hidden;
}

.topicCard:hover {
    transform: translateY(-4px);
    background-color: #3d4756;
}

.topicContent {
    padding: 1.5rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    min-height: 150px;
    position: relative;
    padding-bottom: 2.5rem;
}

.topicHeader {
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.topicIcon {
    color: #9f7aea;
    font-size: 1.25rem;
    flex-shrink: 0;
}

.topicTitle {
    color: #ffffff;
    font-size: 1.25rem;
    font-weight: bold;
    margin: 0;
    line-height: 1.4;
}

.topicDescription {
    color: #cbd5e0;
    font-size: 1rem;
    line-height: 1.5;
    margin: 0;
    flex-grow: 1;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    word-break: break-word;
}

.topicTime {
    color: #718096;
    font-size: 0.875rem;
    /* position: absolute; */
}

.error {
    background-color: #2d3748;
    color: #fc8181;
    padding: 1rem;
    border-radius: 0.5rem;
    text-align: center;
}

@media (max-width: 768px) {
    .mainContainer {
        padding: 1rem;
    }

    .title {
        font-size: 2rem;
    }

    .description {
        font-size: 1rem;
    }

    .topicsGrid {
        grid-template-columns: 1fr;
    }
} 