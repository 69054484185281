.sponsor-container {
  margin-bottom: 15px;
}

.toggle-button {
  background-color: #45a049;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 8px 12px;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 5px;
  transition: background-color 0.3s ease;
}

.toggle-button:hover {
  background-color: #3d8b41;
}

.toggle-button svg {
  margin-right: 5px;
}

.sponsors-wrapper {
  margin-top: 10px;
  display: none;
  flex-direction: column;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.sponsors-wrapper.expanded {
  display: flex;
}

.sponsors-list {
  display: flex;
  flex-direction: row;
  gap: 10px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.sponsors-list::-webkit-scrollbar {
  height: 6px;
}

.sponsors-list::-webkit-scrollbar-thumb {
  background-color: #4a4a4a;
  border-radius: 3px;
}

.sponsor-item {
  background-color: #2a2a2a;
  border-radius: 8px;
  padding: 10px;
  min-width: 200px;
  text-decoration: none;
  color: white;
}

.sponsor-item:hover {
  background-color: #3a3a3a;
}

.sponsor-name {
  margin: 0 0 5px 0;
  color: #45a049;
}

.sponsor-details {
  margin: 0;
  font-size: 12px;
  color: #cccccc;
}
