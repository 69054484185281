.personImageContainer {
  border-radius: 50%;
  overflow: hidden;
  background-color: #4a5568;
  display: flex;
  align-items: center;
  justify-content: center;
}

.personImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.personImagePlaceholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: white;
}
