.entityContainer {
  margin-bottom: 15px;
}

.toggleButton {
  background-color: #ab56fc;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 8px 12px;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 5px;
  transition: background-color 0.3s ease;
}

.toggleButton:hover {
  background-color: #9645e0;
}

.toggleButton svg {
  margin-right: 5px;
}

.sectionTitle {
    color: #a0aec0;
    margin-bottom: 0.5rem;
    font-size: 1.1rem;
    font-weight: bold;
  }

.entitiesWrapper {
  margin-top: 10px;
  display: none;
  flex-direction: column;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.entitiesWrapper.expanded {
  display: flex;
}

.entitiesTitle {
  color: #ffffff;
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: 600;
}

.entitiesList {
  display: flex;
  flex-direction: row;
  gap: 10px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 10px;
}

.entitiesList::-webkit-scrollbar {
  height: 6px;
}

.entitiesList::-webkit-scrollbar-thumb {
  background-color: #4a4a4a;
  border-radius: 3px;
}

.entityItem {
  background-color: #2a2a2a;
  border-radius: 8px;
  padding: 10px;
  min-width: 200px;
  max-width: 350px;
  text-decoration: none;
  color: white;
}

.entityItem:hover {
  background-color: #3a3a3a;
}

.entityName {
  margin: 0 0 5px 0;
  color: #ab56fc;
  font-size: 16px;
}

.entityType {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #cccccc;
}

.entityDetails {
margin-top: 8px;
  font-size: 12px;
  color: #cccccc;
}

.infoIcon {
  margin-right: 5px;
}

.noEntitiesMessage {
  color: #cccccc;
  font-size: 16px;
  text-align: center;
  padding: 20px;
  background-color: #2a2a2a;
  border-radius: 8px;
  margin-top: 10px;
}

.clickable {
  cursor: pointer;
}
