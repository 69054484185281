.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.modalContent {
  background-color: #2d3748;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  position: relative;
  padding: 20px;
  color: white;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  padding: 5px;
}

.modalTitle {
  margin-bottom: 20px;
  font-size: 1.5rem;
}

.infoContent {
  max-height: 60vh;
  overflow-y: auto;
  padding-right: 10px;
}

.infoContent h3 {
  color: #ab56fc;
  margin: 0.5rem 0 0.5rem 0;
  font-size: 1.2rem;
}

.infoContent p {
  margin: 1rem 0;
  line-height: 1.5;
  color: #a0aec0;
}

.infoContent ul {
  list-style-type: none;
  padding-left: 1rem;
  margin: 1rem 0;
}

.infoContent li {
  color: #a0aec0;
  margin: 0.5rem 0;
  position: relative;
  padding-left: 1rem;
}

.infoContent li:before {
  content: "•";
  color: #ab56fc;
  position: absolute;
  left: -0.5rem;
}
