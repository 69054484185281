.personContainer {
  display: inline-flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.personInfo {
  position: absolute;
  left: 100%;
  margin-left: 0.5rem;
  background-color: #1a202c;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  white-space: nowrap;
}

.personName {
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
}

.personRole {
  font-size: 0.9rem;
  color: #a0aec0;
  margin: 0.2rem 0 0 0;
}

