.app {
  display: flex;
  flex-direction: column;
  /* min-height: 100vh; */
  background-color: #1a202c;
}

.mobileApp {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
  padding-top: 64px; /* Add padding for desktop navbar height */
}

.mobileContent {
  padding-top: 60px; /* Adjust this value based on the height of your top navbar */
  padding-bottom: 60px; /* Adjust this value based on the height of your bottom navbar */
}

.desktopContent {
  padding-top: 80px; /* Increase padding for desktop view */
}

@media (max-width: 767px) {
  .content {
    padding-bottom: calc(1rem + 64px); /* Adjust this value based on your mobile navbar height */
  }
}
