.episodeImageContainer {
  position: relative;
  width: 100%;
  max-width: 300px;
  aspect-ratio: 1 / 1;
  background-color: #1a202c;
  border-radius: 0.5rem;
  overflow: hidden;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .episodeImageContainer {
    width: 100vw;
    max-width: 100%;
    border-radius: 0;
  }
  
  .episodeImage {
    border-radius: 0;
  }
}

.episodeImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
}

.audioPlayerOverlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.menuContainer {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 10;
}

.menuButton {
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: #ab56fc;
  font-size: 2.0rem;
  cursor: pointer;
  padding: 10px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menuDropdown {
  position: absolute;
  right: 0;
  top: 100%;
  background-color: #2d3748;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
  margin-top: 5px;
}

.menuItem {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  color: #f7fafc;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  transition: background-color 0.2s;
}

.menuItem:hover {
  background-color: #4a5568;
}

.menuIcon {
  margin-right: 10px;
}

.toastSuccess {
  background-color: #1a202c !important;
  color: white !important;
}

.toastError {
  background-color: #e53e3e !important;
  color: white !important;
} 

.diveIntoEpisodeButton {
    position: absolute;
    top: 15px;
    right: 10px;
    background-color: rgba(171, 86, 252, 0.8);
    color: white;
    border: none;
    border-radius: 20px;
    padding: 8px 16px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease;
  }
  
  .diveIntoEpisodeButton:hover {
    background-color: rgba(171, 86, 252, 1);
  }

  .arrowIcon {
    margin-left: 8px;
  }
  