.health-check-container {
    @apply min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 text-gray-100 font-sans flex flex-col items-center justify-center p-4;
}

.health-check-title {
    @apply text-4xl md:text-6xl font-bold mb-8 md:mb-12 text-center;
}

.health-check-status {
    @apply bg-gray-800 p-6 md:p-8 rounded-lg shadow-lg mb-8 md:mb-12 w-full max-w-md;
}

.status-item {
    @apply mb-4 last:mb-0;
}

.status-label {
    @apply text-lg md:text-xl font-semibold text-gray-400 block mb-1;
}

.status-value {
    @apply text-xl md:text-2xl font-bold;
}

.status-value.ok {
    @apply text-green-500;
}

.status-value.error {
    @apply text-red-500;
}

.refresh-button {
    @apply bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-full transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50;
}
