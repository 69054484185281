.playerContainer {
  min-height: 100vh;
  background-color: #1a202c;
  color: #f7fafc;
  font-family: Arial, sans-serif;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

.episodeTitle {
  color: #f7fafc;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.episodeImageContainer {
  position: relative;
  width: 100%;
  min-width: 300px;
}

.episodeImage {
  width: 100%;
  height: auto;
  border-radius: 0.5rem;
}

.showTitle {
  font-size: 1rem;
  font-weight: bold;
  color: #ab56fc;
  margin-bottom: 0.5rem;
}

.audioPlayerOverlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

@keyframes pulse {
  0% { background-position: 200% 0; }
  100% { background-position: -200% 0; }
}

.skeletonPulse {
  animation: pulse 1.5s infinite;
  background: linear-gradient(0.25turn, transparent, #ab56fc, transparent);
  background-size: 200% 100%;
}

.skeletonImage {
  composes: skeletonPulse;
  width: 100%;
  padding-top: 100%;
  margin-bottom: 20px;
  border-radius: 8px;
}

.skeletonText {
  composes: skeletonPulse;
  height: 20px;
  margin-bottom: 10px;
  border-radius: 4px;
}

.tagsContainer {
  padding: 1rem;
  background-color: #1a202c;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

.tagsTitle {
  color: #a0aec0;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1.25rem;
  font-weight: bold;
}

.buttonsContainer {
  margin-top: 2rem;
  padding: 1rem;
  background-color: #2d3748;
  border-radius: 0.5rem;
}

.excerptsContainer {
  padding: 1.0rem;
  background-color: #2d3748;
  border-radius: 0.5rem;
}

.excerptsList {
  list-style-type: none;
  padding: 0;
  overflow-y: auto;
  max-height: 850px;
}

.excerptTitle {
  color: #a0aec0;
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
  font-weight: bold;
}

.excerptItemWrapper {
  margin-bottom: 1rem;
}

.saveButton {
  background-color: #ab56fc;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
  width: 100%;
}

.saveButton:hover {
  background-color: #553c9a;
}

.currentlyPlayingContainer {
  margin-top: 1.5rem;
  padding: 1rem;
  background-color: #2d3748;
  border-radius: 0.5rem;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.currentlyPlayingContainer p {
  margin-bottom: 1rem;
  line-height: 1.5;
  max-width: 100%;
}

.currentlyPlayingContainer a {
  color: #ab56fc;
  word-break: break-word;
  display: inline-block;
  max-width: 100%;
}

.currentlyPlayingContainer a:hover {
  color: #553c9a;
}

.currentlyPlayingContainer ul,
.currentlyPlayingContainer ol {
  margin: 1rem 0;
  padding-left: 2rem;
}

.currentlyPlayingContainer li {
  margin-bottom: 0.5rem;
}

.sponsorsTitle,
.currentlyPlayingTitle,
.sectionTitle {
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
  font-weight: bold;
}

.currentlyPlayingTitle {
  color: #a0aec0;
}

.sponsorsTitle {
  color: #45a049;
}

.currentlyPlayingSubsection {
  margin-bottom: 0.5rem;
}

.responsivePlayerLayout {
  display: flex;
  flex-direction: column;
}

.playerMainContent {
  margin-bottom: 2rem;
}

.episodeHeader {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.episodeInfo {
  flex: 1;
  min-width: 0;
}

.episodeImageContainer {
  position: relative;
  width: 100%;
  min-width: 300px;
}

.personList {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
  margin-bottom: 20px;
}

.publishedDate {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 0.9rem;
  color: #a0aec0;
  margin-bottom: 0.5rem;
}

.calendarIcon {
  font-size: 14px;
}

@media (min-width: 768px) {
  .responsivePlayerLayout {
    flex-direction: row;
    justify-content: space-between;
  }

  .playerMainContent {
    width: 60%;
    margin-right: 2rem;
  }

  .episodeHeader {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 2rem;
  }

  .episodeInfo {
    width: 50%;
    max-width: 60%;
  }

  .episodeImageContainer {
    width: 40%;
    max-width: 400px;
  }

  .excerptsContainer {
    width: 40%;
  }

  .personList {
    justify-content: flex-start;
  }
}

.excerptCounter {
  text-align: center;
  font-size: 0.9rem;
  color: #a0aec0;
  margin-bottom: 0.5rem;
}

.navigationButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
}

.navButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: #ab56fc;
  color: white;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.navButton:hover:not(:disabled) {
  background-color: #553c9a;
}

.navButton:disabled {
  background-color: #4a5568;
  cursor: not-allowed;
}

.guestsAndMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}

.menuContainer {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 10;
}

.menuButton {
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: #ab56fc;
  font-size: 2.0rem;
  cursor: pointer;
  padding: 10px 5px;
  /* border-radius: 75%; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.menuDropdown {
  position: absolute;
  right: 0;
  top: 100%;
  background-color: #2d3748;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
  margin-top: 5px;
}

.menuItem {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  color: #f7fafc;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  transition: background-color 0.2s;
}

.menuItem:hover {
  background-color: #4a5568;
}

.menuIcon {
  margin-right: 10px;
}

.toastSuccess {
  background-color: #1a202c !important;
  color: white !important;
}

.toastError {
  background-color: #e53e3e !important;
  color: white !important;
}

.customToast {
  background-color: #2d3748 !important;
  color: #f7fafc !important;
}

.customProgress {
  background: #ab56fc !important;
}

.pullItUpHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.exploreButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: #2d3748;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  color: #fff;
  transition: background-color 0.2s;
}

.exploreButton:hover {
  background-color: #4a5568;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background-color: #1a202c;
  border-radius: 0.5rem;
  width: 90%;
  max-width: 600px;
  max-height: 80vh;
  overflow-y: auto;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #2d3748;
}

.modalHeader h3 {
  font-size: 1.25rem;
  font-weight: 600;
}

.closeButton {
  font-size: 1.5rem;
  color: #a0aec0;
  background: none;
  border: none;
  cursor: pointer;
}

.modalBody {
  padding: 1rem;
}

.entityTypeSection {
  border-bottom: 1px solid #2d3748;
}

.entityTypeHeader {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  width: 100%;
  padding: 1rem;
  background: none;
  border: none;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.2s;
}

.entityTypeHeader:hover {
  background-color: #2d3748;
}

.entityTypeHeader svg {
  font-size: 0.875rem;
  flex-shrink: 0;
}

.entityTypeContent {
  padding: 0.5rem 1rem 1rem 2.75rem;
}

.modalContent {
  background-color: #1a202c;
  border-radius: 0.5rem;
  width: 90%;
  max-width: 600px;
  max-height: 80vh;
  overflow-y: auto;
}

.modalContent::-webkit-scrollbar {
  width: 8px;
}

.modalContent::-webkit-scrollbar-track {
  background: #2d3748;
}

.modalContent::-webkit-scrollbar-thumb {
  background-color: #4a5568;
  border-radius: 4px;
}

.modalHeader {
  position: sticky;
  top: 0;
  background-color: #1a202c;
  z-index: 1;
}

.ingestionIcon {
  font-size: 0.875rem;
}

.ingestionControls {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.ingestionButton {
  background-color: #ab56fc;
  color: #f7fafc;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.25rem;
  margin-top: 0.5rem;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  flex: 1;
  max-width: 300px;
}

.ingestionButton:hover {
  background-color: #553c9a;
}

.infoButton {
  color: #ab56fc;
  color: #f7fafc;
  padding: 0.5rem;
  border: none;
  border-radius: 0.25rem;
  margin-top: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.infoButton:hover {
  background-color: #4a5568;
}
